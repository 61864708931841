// IMPORTS

// general
import * as React from "react";
import { useEffect } from "react";

// local components
// import GroupCard from "../components/GroupCard";
import TeamMemberCard from "../components/TeamMemberCard";
import SectionDivider from "../components/SectionDivider";

// local images
// import headShotTeam from "../assets/images/headshots/headshotTeam.jpeg";
import teslaHeadshot from "../assets/images/headshots//headshotTesla.jpeg";
// import marigraceHeadshot from "../assets/images/headshots/headshotMarigrace.jpeg";
import cassidyHeadshot from "../assets/images/headshots/headshotCassidy.jpeg";
import leahHeadshot from "../assets/images/headshots/headshotLeah.jpeg";
import aleahHeadshot from "../assets/images/headshots/headshotAleah.jpeg";

// local styles
import "../assets/App.css";
import "../assets/imageSource.css";

// Material UI Components

// Material UI Icons
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

// animations

// PROPS

// const groupInfo = {
//   photo: headShotTeam,
//   name: "Meet the Team",
//   message: `Click "Contact" from the navigation menu for more information on how to contact the salon - or while you're here, get to know the team!`,
// };

const teslaAnderson = {
  photo: teslaHeadshot,
  name: "Tesla Anderson",
  title: "Owner | Hairstylist",
  licenseYear: "2012",
  specialty: "Hair Extensions, Custom Color Services",
  phoneTag: "352-460-3908",
  socialIcon: <InstagramIcon />,
  socialLabel: "Instagram",
  socialTag: "@enhancedhair_bytesla",
  socialLink: "https://www.instagram.com/enhancedhair_bytesla/",
};

// const marigraceBrumley = {
//   photo: marigraceHeadshot,
//   name: "Marigrace Brumley",
//   title: "Hairstylist",
//   licenseYear: "2019",
//   specialty: "Custom Color Services, Vivid Color, Custom Reds/Coppers",
//   phoneTag: "352-396-5264",
//   socialIcon: <InstagramIcon />,
//   socialLabel: "Instagram",
//   socialTag: "@graciousbeautyy",
//   socialLink: "https://www.instagram.com/graciousbeautyy/",
// };

const cassidyGraham = {
  photo: cassidyHeadshot,
  name: "Cassidy Graham",
  title: "Hairstylist",
  licenseYear: "2019",
  specialty: "Lived-in Colors, Corrective Colors/Cuts.",
  phoneTag: "352-205-1514",
  socialIcon: <InstagramIcon />,
  socialLabel: "Instagram",
  socialTag: "@casey_shauna_creations",
  socialLink: "https://www.instagram.com/casey_shauna_creations/",
};

const leahHansard = {
  photo: leahHeadshot,
  name: "Leah Hansard",
  title: "Hairstylist",
  licenseYear: "2017",
  specialty: "Blonding, Lived-in Dimensional Colors",
  phoneTag: "352-973-2045",
  socialIcon: <InstagramIcon />,
  socialLabel: "Instagram",
  socialTag: "@leahdoesmyhair",
  socialLink: "https://www.instagram.com/leahdoesmyhair/",
};

const aleahSeaux = {
  photo: aleahHeadshot,
  name: "Aleah Seaux",
  title: "Hairstylist",
  licenseYear: "2020",
  specialty: "Custom Color Services, Gray Blending",
  phoneTag: "352-818-2696",
  socialIcon: <FacebookIcon />,
  socialLabel: "Facebook",
  socialTag: "Hair by Aleah Seaux",
  socialLink: "https://www.facebook.com/p/Hair-by-Aleah-Seaux-100083413956986/",
};

// Styles
// const arrowStyle = {
//   color: "white",
//   display: "flex",
//   m: "auto",
// };

export const MeetTeam = () => {
  // Hooks

  // Functions
  useEffect(() => {
    const pageTitleElement = document.getElementById("pageTitle");
    if (pageTitleElement) {
      pageTitleElement.scrollIntoView({ behavior: "instant" });
    }
  });

  return (
    <>
      <SectionDivider />
      <h1 className="page-title">Meet the Team</h1>
      <hr />
      <div className="meet-team-container">
        {/* <GroupCard
          photo={groupInfo.photo}
          name={groupInfo.name}
          message={groupInfo.message}
        /> */}
        {/* tesla anderson */}
        <TeamMemberCard
          photo={teslaAnderson.photo}
          name={teslaAnderson.name}
          title={teslaAnderson.title}
          licenseYear={teslaAnderson.licenseYear}
          specialty={teslaAnderson.specialty}
          phoneTag={teslaAnderson.phoneTag}
          socialIcon={teslaAnderson.socialIcon}
          socialLabel={teslaAnderson.socialLabel}
          socialTag={teslaAnderson.socialTag}
          socialLink={teslaAnderson.socialLink}
          //
        />
        {/* marigrace brumley */}
        {/* <TeamMemberCard
          photo={marigraceBrumley.photo}
          name={marigraceBrumley.name}
          title={marigraceBrumley.title}
          licenseYear={marigraceBrumley.licenseYear}
          specialty={marigraceBrumley.specialty}
          phoneTag={marigraceBrumley.phoneTag}
          socialIcon={marigraceBrumley.socialIcon}
          socialLabel={marigraceBrumley.socialLabel}
          socialTag={marigraceBrumley.socialTag}
          socialLink={marigraceBrumley.socialLink}
        /> */}
        {/* cassidy graham */}
        <TeamMemberCard
          photo={cassidyGraham.photo}
          name={cassidyGraham.name}
          title={cassidyGraham.title}
          licenseYear={cassidyGraham.licenseYear}
          specialty={cassidyGraham.specialty}
          phoneTag={cassidyGraham.phoneTag}
          socialIcon={cassidyGraham.socialIcon}
          socialLabel={cassidyGraham.socialLabel}
          socialTag={cassidyGraham.socialTag}
          socialLink={cassidyGraham.socialLink}
        />
        {/* leah hansard */}
        <TeamMemberCard
          photo={leahHansard.photo}
          name={leahHansard.name}
          title={leahHansard.title}
          licenseYear={leahHansard.licenseYear}
          specialty={leahHansard.specialty}
          phoneTag={leahHansard.phoneTag}
          socialIcon={leahHansard.socialIcon}
          socialLabel={leahHansard.socialLabel}
          socialTag={leahHansard.socialTag}
          socialLink={leahHansard.socialLink}
        />
        {/* aleah seaux */}
        <TeamMemberCard
          photo={aleahSeaux.photo}
          name={aleahSeaux.name}
          title={aleahSeaux.title}
          licenseYear={aleahSeaux.licenseYear}
          specialty={aleahSeaux.specialty}
          phoneTag={aleahSeaux.phoneTag}
          socialIcon={aleahSeaux.socialIcon}
          socialLabel={aleahSeaux.socialLabel}
          socialTag={aleahSeaux.socialTag}
          socialLink={aleahSeaux.socialLink}
        />
      </div>
    </>
  );
};

export default MeetTeam;
